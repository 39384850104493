<template>
  <div>
    <div class="right" style="padding-bottom: 2vh" v-if="!disabled">
      <a-button type="primary" @click="add">添加</a-button>
    </div>

    <a-table
      bordered
      size="small"
      :dataSource="selected"
      rowKey="id"
      :pagination="false"
    >
      <a-table-column key="code" title="资产编号" data-index="code" />
      <a-table-column key="name" title="资产名称" data-index="name" />
      <a-table-column key="brand" title="品牌" data-index="brand" />
      <a-table-column
        key="specification"
        title="规格型号"
        data-index="specification"
      />
      <a-table-column key="unit" title="单位" data-index="unit" />
      <a-table-column key="price" title="单价(元)" align="right">
        <template slot-scope="text">
          <Money :money="text.price" />
        </template>
      </a-table-column>
      <a-table-column key="charger" title="原保管人" align="center">
        <template slot-scope="text">
          {{ text.charger ? text.charger : text.chargerOld }}
        </template>
      </a-table-column>
      <a-table-column key="chargerNewId" title="现保管人" align="center">
        <template slot-scope="text">
          <div v-if="disabled">{{ text.chargerNew }}</div>
          <a-select v-else v-model="text.chargerNewId" size="small">
            <a-select-option
              v-for="item in userList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </template>
      </a-table-column>
      <a-table-column
        key="control"
        title="操作"
        align="center"
        v-if="!disabled"
      >
        <template slot-scope="text, row, index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      title="选择资产"
      :visible="visible"
      @cancel="cancel"
      @ok="ok"
      width="800px"
    >
      <a-table
        bordered
        size="small"
        :dataSource="list"
        @change="onChange"
        rowKey="id"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column key="code" title="资产编号" data-index="code" />
        <a-table-column key="name" title="资产名称" data-index="name" />
        <a-table-column key="brand" title="品牌" data-index="brand" />
        <a-table-column
          key="specification"
          title="规格型号"
          data-index="specification"
        />
        <a-table-column key="unit" title="单位" data-index="unit" />
        <a-table-column
          key="price"
          title="单价(元)"
          data-index="price"
          align="right"
        />
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchList } from "../../api/list";
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    userList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
      selectedRowKeys: [],
      selectedRows: [],

      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        // statusList: ["AVAILABLE"],
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.list = res.list;
          this.total = res.totalSize;
        }
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    onSelectChange(keys) {
      console.log("keys", keys);
      this.selectedRowKeys = keys;
      keys.forEach((key) => {
        const item = this.list.find((item) => item.id === key);
        if (item) {
          this.selectedRows.push(item);
        }
      });
      this.selectedRows = [...new Set(this.selectedRows)];
    },

    add() {
      this.visible = true;
    },

    remove(index) {
      this.selected.splice(index, 1);
    },

    cancel() {
      this.visible = false;
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    ok() {
      this.selectedRows.forEach((item) => {
        const element = this.selected.find((element) => element.id === item.id);
        if (!element) {
          this.selected.push(item);
        }
      });
      this.cancel();
    },
  },
};
</script>